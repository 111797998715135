import request from '@/utils/request'

export default {
  matchSignList() {
    return request({
      url: `/youth/match/matchSignList`,
      method: 'get'
    })
  }
}
