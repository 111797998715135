<template>
  <div class="screen">
    <el-card shadow="always" class="screenHead">
      <div style="font-size:60px;margin-bottom:10px">青春中国系列展演活动</div>
      <div>主办方：江苏省青少年文化交流中心</div>
    </el-card>
    <div style="margin-top:300px">
      <el-select v-model="signMatch" placeholder="请选择比赛" style="width:400px;" @change="getPlaceList">
        <el-option v-for="item in matchList" :key="item.id" :label="item.matchName" :value="item.id"/>
      </el-select>
      <br/><br/>
      <el-select v-model="signPlace" placeholder="请选择比赛场次" style="width:400px;">
        <el-option v-for="item in placeList" :key="item.id" :label="item.place" :value="item.id">
        </el-option>
      </el-select>
      <div style="margin:50px"><el-button size="medium" type="primary" @click="showSign" style="width:400px;">确认</el-button></div>
    </div>
  </div>
</template>

<script>
import enrollApi from '../api/enroll.js'
import matchApi from '../api/match.js'

export default {
  data() {
    return {
      matchList:{},
      placeList:{},
      signPlace:[],
      signMatch:[]
    }
  },
  methods: {
    getMatch() {
      matchApi.matchSignList().then(res => {
        if(res.data.success){
          this.matchList = res.data.data.list
        }
      })
    },
    getPlaceList(item) {
      enrollApi.getSignPlace(item).then(res => {
        if(res.data.success){
          this.placeList = res.data.data.list
        }
      })
    },
    showSign(){
      this.$router.push({path:'/signShowNew',query:{place:this.signPlace}})
    }
  },
  mounted() {
    this.getMatch.call(this)
  }
}
</script>
<style scoped>
.screen{
  margin: 0 auto;
  padding: 30px 50px;
}
.screenHead{
  margin-bottom: 20px;
  background-image: url(../assets/11.jpg);
  color:#fff;
}
</style>